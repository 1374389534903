<template>
    <div class="board-container">
        <div class="gallery-items">
            <div class="gallery-item" v-for="board in notices" :key="board.index" @click="showBoard(board)">
                <a href="#">
                    <div class="gallery-item__inner">
                        <div class="gallery-item__img" :style="`background-image:url(${board.thumb});`"></div>
                        <div class="gallery-item__con">
                            <h2 class="gallery-item__tit">{{ board.subject }}</h2>
                        </div>
                    </div>
                </a>
            </div>
            <div class="gallery-item" v-for="board in boards" :key="board.index" @click="showBoard(board)">
                <a href="#">
                    <div class="gallery-item__inner">
                        <div class="gallery-item__img" :style="`background-image:url(${board.thumb});`"></div>
                        <div class="gallery-item__con">
                            <h2 class="gallery-item__tit">{{ board.subject }}</h2>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="board-bottom">
            <pagination-component :count="count" :value="page" @change="changePage" />
        </div>
        <!-- <div class="board-container">
            <div class="grid">
                <div class="grid-8" v-for="board in notices" :key="board.index" @click="showBoard(board)">
                    <div role="link" class="product-list product-list--no-border">
                        <span class="product-list__thumb">
                            <span class="product-list__thumbnail">
                                <span class="product-list__thumbnail-image" :style="{ backgroundImage: `url('${board.thumb}')` }"></span>
                            </span>
                            <span class="product-list__mask">
                                <span class="product-list__view">조회수 {{ board.viewCount }}</span>
                            </span>
                        </span>
                        <p class="brief">{{ board.subject }}</p>
                    </div>
                </div>
                <div class="grid-8" v-for="board in boards" :key="board.index" @click="showBoard(board)">
                    <div role="link" class="product-list product-list--no-border">
                        <span class="product-list__thumb">
                            <span class="product-list__thumbnail">
                                <span class="product-list__thumbnail-image" :style="{ backgroundImage: `url('${board.thumb}')` }"></span>
                            </span>
                            <span class="product-list__mask">
                                <span class="product-list__view">조회수 {{ board.viewCount }}</span>
                            </span>
                        </span>
                        <p class="brief">{{ board.subject }}</p>
                    </div>
                </div>
            </div>
            <div class="board-buttons board-buttons--center">
                <pagination-component :count="count" :value="page" @change="changePage" />
            </div>
        </div> -->
    </div>
</template>

<script>
import paginationComponent from "@/components/client/ko/control/pagination-component.vue";
export default {
    components: { paginationComponent },
    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(board) {
            this.$router.push(`${this.$route.path}/${board._id}`);
        },
    },
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
    },
};
</script>
